<template>
  <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${this.form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row align-v="end">
            <b-col cols="5">
              <div class="form-group">
                <validation-provider
                  name="cirurgia"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-if="!fields.custom"
                    v-model="fields.cirurgia"
                    id="cirurgia"
                    :options="cirurgiasOftalmologicas"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :showNoResults="true"
                    placeholder="Nome da cirurgia"
                    class="search-mode with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar cirurgia oftalmológica "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                  </multiselect>
                  <div class="custom-input" v-else>
                    <b-button variant="link" @click="setCustom(false)"
                      >Selecionar um item da lista</b-button
                    >
                    <input
                      autocomplete="off"
                      v-model="fields.cirurgia"
                      :readonly="!canEdit"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    />
                  </div>
                </validation-provider>
              </div>
            </b-col>
            <b-col class="3">
              <div class="form-group">
                <validation-provider
                  name="lateralidade"
                  :rules="{ required: false }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-model="fields.lateralidade"
                    :options="lateralidadeOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :showNoResults="true"
                    placeholder="Lateralidade"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar cirurgia oftalmológica "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="form-group">
                <validation-provider name="data" :rules="{ required: false }">
                  <date-picker
                    v-model="fields.data"
                    id="data"
                    format="DD/MM/YYYY"
                    placeholder="DD/MM/AAAA"
                    :lang="langDatePicker"
                    :disabled-date="disabledDates"
                    :clearable="false"
                    :readonly="!canEdit"
                    class="full"
                    autocomplete="off"
                  />
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 mt-27 button"
                :disabled="invalid"
                @click="saveItem"
                >Adicionar</b-button
              >
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th width="51%">Nome</th>
                  <th>Lateralidade</th>
                  <th>Data</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td
                    v-b-tooltip.hover :title="value?.cirurgia?.length >= 120 ? value.cirurgia : ''"
                  >
                    {{ value?.cirurgia?.length >= 120 ? value.cirurgia.slice(0, 120)+'...' : value.cirurgia }}
                  </td>
                  <td>{{ value.lateralidade }}</td>
                  <td>{{ parseDate(value.data) }}</td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          @click="editItem(value, index)"
                          :disabled="!canEdit"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    MoreVertical
  },
  inject: ['cirurgiasOftalmologicas'],
  data() {
    return {
      fields: {
        custom: false,
        cirurgia: null,
        lateralidade: null,
        data: null
      },
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      lateralidadeOptions: [
        'Ambos os olhos',
        'Olho direito',
        'Olho esquerdo'
      ]
    }
  },  
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.cirurgiasOftalmologicasAnteriores,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/cirurgiasOftalmologicasAnteriores', ['handleFields', 'handleProps']),
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.cirurgia = value ? searchTerm : null
    },
    saveItem() {
      const hasCirurgia = this.form.value.some(v => 
        v.cirurgia.toLowerCase() === this.fields.cirurgia.toLowerCase() &&
        v.lateralidade === this.fields.lateralidade &&
        this.moment(v.data).format('DD/MM/YYYY') === this.moment(this.fields.data).format('DD/MM/YYYY')
      )
      
      if (this.editingIndex == null && hasCirurgia){
        this.$toast.warning('Cirurgia oftalmológica já adicionada')
        return
      }

      let value = [...this.form.value]
      if (this.editingIndex != null && this.editingIndex >= 0) {
        value.splice(this.editingIndex, 1)
      }
      value = [...value]
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)

      this.fields = {
        custom: false,
        cirurgia: null,
        data: null
      }

      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.fields.data = this.fields.data ? this.moment(this.fields.data).toDate() : null
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    parseDate(date) {
      if (date && this.moment(date).isValid()) {
        return this.moment(date).format('DD/MM/YYYY')
      }
      return date
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
    },
    disabledDates(date) {
      return date > new Date()
    },
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 10px;
}
</style>
